<template>
  <v-container id="user-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.store_code"
                    label="Store Code"
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <label>Logo</label>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <img
                    :src="getImage('/' + editedItem.logo_path_url)"
                    height="100px"
                    width="100px"
                    contain
                  />
                  <v-file-input
                    truncate-length="15"
                    @change="onFileSelected"
                    ref="fileLogo"
                    required
                    :rules="logoRules"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.store"
                    label="Store Name"
                    required
                    :rules="storeRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    v-model="editedItem.location_store"
                    label="Address Store"
                    required
                    :rules="locationStoreRules"
                    height="100"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.contact"
                    label="Contact Store"
                    required
                    :rules="contactRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12"
                  ><v-select
                    v-model="province_id"
                    :items="provinces"
                    item-text="province"
                    item-value="province_id"
                    label="Provinsi"
                    persistent-hint
                    single-line
                    ref="provincesReff"
                  ></v-select
                ></v-col>
                <v-col cols="12" sm="6" md="12"
                  ><v-select
                    v-model="city_id"
                    :items="citiesByProvince"
                    item-text="city_name"
                    item-value="city_id"
                    label="Kota"
                    persistent-hint
                    single-line
                  ></v-select
                ></v-col>
                <v-col cols="12" sm="6" md="12"
                  ><v-select
                    v-model="subdistrict_id"
                    :items="subdistrictByCities"
                    item-text="subdistrict_name"
                    item-value="subdistrict_id"
                    label="Kecamatan"
                    persistent-hint
                    single-line
                    required
                    :rules="subdistrictRules"
                  ></v-select
                ></v-col>

                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.tax"
                    label="Tax Price"
                    type="number"
                    prefix="Rp"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.midtrans_cost"
                    label="Tax Midtrans Price"
                    type="number"
                    prefix="Rp"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.type_information"
                    label="Type informatin"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    v-model="editedItem.information"
                    label="Information"
                    height="100"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-textarea
                    v-model="editedItem.policy"
                    label="Police"
                    height="100"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions class="justify-center">
          <v-btn color="#1b5e20" class="white--text" elevation="2" @click="save">
            Save
          </v-btn>
          <v-btn @click="reset" color="#1b5e20" class="white--text" elevation="2">
            Reset
          </v-btn>
        </v-card-actions>
      </v-card-title>
    </base-material-card>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        >
        </v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    province_id: 0,
    city_id: 0,
    subdistrict_id: 0,
    progressBar: false,
    selectedFile: null,
    editedItem: {
      store: "",
      logo_path_url: "",
      location_store: "",
      contact: "",
      tax: 0,
      store_code: "",
      information: "",
      type_information: "",
      policy: "",
      midtrans_cost: 0,
    },
    defaultItem: {
      store: "",
      logo_path_url: "",
      location_store: "",
      contact: "",
      tax: 0,
      store_code: "",
      information: "",
      type_information: "",
      policy: "",
      midtrans_cost: 0,
    },
    storeRules: [
      (v) => !!v || "Store Name is required",
      (v) => (v && v.length <= 20) || "Max 20 characters",
    ],
    subdistrictRules: [(v) => !!v || "Kecamatan is required"],
    //logoRules: [(v) => !!v || "Logo is required"],
    locationStoreRules: [
      (v) => !!v || "Address store is required",
      (v) => (v && v.length <= 100) || "Max 30 characters",
    ],
    contactRules: [
      (v) => !!v || "Contact store is required",
      (v) => (v && v.length <= 30) || "Max 30 characters",
    ],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
      setting: "setting/setting",
      provinces: "region/provinces",
      cities: "region/cities",
      subdistrict: "region/subdistrict",
    }),
    citiesByProvince() {
      let province_id = this.province_id;
      // this.subdistrict_id = 0;
      return this.cities.filter((city) => {
        if (city.province_id == province_id) return city;
      });
    },
    subdistrictByCities() {
      let city_id = this.city_id;
      this.selectSubdistrict();
      return this.subdistrict.filter((subdistrict) => {
        if (subdistrict.city_id == city_id) return subdistrict;
      });
    },
  },
  created() {
    this.go();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
      setSetting: "setting/set",
      setProvinces: "region/setProvinces",
      setCities: "region/setCities",
      setSubdistrict: "region/setSubdistrict",
    }),
    onFileSelected(event) {
      this.selectedFile = event;
    },
    selectSubdistrict() {
      if (this.city_id != 0) {
        let city_id = this.city_id;
        this.axios.get("/subdistrict?cities_id=" + parseInt(city_id)).then((response) => {
          let { data } = response.data;
          this.setSubdistrict(data);
        });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("store", this.editedItem.store);
        formData.set("logo", this.selectedFile);
        formData.set("user_id", this.user.id);
        formData.set("id", this.editedItem.id);
        formData.set("address", this.editedItem.location_store);
        formData.set("contact", this.editedItem.contact);
        formData.set("tax", this.editedItem.tax);
        formData.set("provinces_id", this.province_id);
        formData.set("cities_id", this.city_id);
        formData.set("subdistrict_id", this.subdistrict_id);

        formData.set("information", this.editedItem.information);
        formData.set("type_information", this.editedItem.type_information);
        formData.set("policy", this.editedItem.policy);
        formData.set("midtrans_cost", this.editedItem.midtrans_cost);

        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        this.axios
          .post("/store-setting/store", formData, config)
          .then((response) => {
            let { data } = response;
            this.setAuth(data.data);
            this.setAlert({
              status: true,
              text: data.message,
              color: "success",
            });
            this.clear();
          })
          .catch((error) => {
            let { data } = error;
            this.setAlert({
              status: true,
              text: data.message,
              color: "error",
            });
            this.clear();
          });
      }
    },
    go() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/store-setting";
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          this.editedItem = data;
          this.setSetting(data);
          this.subdistrict_id = this.editedItem.location_id;
          this.city_id = this.editedItem.cities_id;
          this.province_id = this.editedItem.provinces_id;
          if (this.provinces && this.provinces.length == 0) {
            this.axios.get("/provinces").then((response) => {
              let { data } = response.data;
              this.setProvinces(data);
              //console.log(data);
            });

            this.axios.get("/cities").then((response) => {
              let { data } = response.data;
              this.setCities(data);
            });

            this.axios.get("/subdistrict").then((response) => {
              let { data } = response.data;
              this.setSubdistrict(data);
            });
          }
          this.$refs.provincesReff.update();
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    clear() {
      this.progressBar = false;
      window.location.reload();
    },
    reset() {
      this.$refs.fileLogo.reset();
      this.$refs.form.reset();
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
